.welcome-container {
  background-color: white;
  border-radius: 10px;
  height: 35vh;
  width: 40vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
}

.welcome-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
}

.welcome-header i {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.welcome-header-time {
  font-size: 2.5rem;
}

/* Media query for devices with a maximum width of 768 pixels */
@media only screen and (max-width: 768px) {
  .welcome-container {
    width: 60vw;
  }
}
