/*=============== VARIABLES CSS ===============*/
:root {
  /*========== Colors ==========*/
  --primary-color: aliceblue;
  --primary-color-dark: rgb(63, 113, 156);
}

.App {
  text-align: center;
}

.page {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
}

.page-title {
  font-size: 3rem;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.link-button {
  text-decoration: none;
  background-color: var(--primary-color-dark);
  color: white;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 1rem;
  font-weight: 500;
}

/* Media query for devices with a maximum width of 768 pixels */
@media only screen and (max-width: 1024px) {
  .page-title {
    font-size: 2rem;
    padding: 0rem;
  }
}
@media only screen and (max-width: 768px) {
  .page-title {
    font-size: 1.5rem;
    padding: 0rem;
  }
  .link-button {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    font-weight: 400;
    border-radius: 0.5rem;
    left: 1.5rem;
  }
}
