.controls {
  width: 70%;
}

.controls-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.controls-title {
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-left: 1rem;
}

.controls-title-text {
  width: 10rem;
}

.controls-inputs {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 1rem;
}

.controls-month,
.controls-year,
.controls-date {
  box-sizing: border-box;
  height: 2rem;
  border: 1px solid rgb(168, 184, 198);
  text-align: center;
  border-radius: 5px;
  background-color: white;
  width: 7rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  font-family: Arial;
  color: black;
}

.controls-month {
  background-color: solid white;
}

.controls-chevron {
  cursor: pointer;
  font-size: 2rem;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  color: black;
}

/* Media query for devices with a maximum width of 1024 pixels */
@media only screen and (max-width: 1024px) {
  .controls-container {
    flex-direction: column;
    gap: 1rem;
  }

  .controls-title {
    font-size: 1.5rem;
    gap: 0;
  }
}

/* Media query for devices with a maximum width of 768 pixels */
@media only screen and (max-width: 768px) {
  .controls-inputs {
    justify-content: space-between;
  }

  .controls-month,
  .controls-year,
  .controls-date {
    height: 1.8rem;
    width: 6rem;
    font-size: 0.8rem;
  }
  .controls-title-text {
    width: 7rem;
  }
}
